import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString } from "vue";
const _hoisted_1 = {
  class: "subbody mbgray modulewrap"
};
const _hoisted_2 = {
  class: "teacherwrap realwrap"
};
const _hoisted_3 = {
  class: "teachercover imgmove"
};
const _hoisted_4 = ["src"];
const _hoisted_5 = {
  class: "teacherinfo"
};
const _hoisted_6 = {
  class: "teachertitle"
};
const _hoisted_7 = {
  class: "teachertitle2"
};
const _hoisted_8 = ["innerHTML"];
import { FFmixin } from "@/mixin/mixin.js";
export default {
  __name: 'teaching',
  setup(__props) {
    const {
      proxy
    } = getCurrentInstance();
    const {
      setTitle
    } = FFmixin();
    const FFdata = reactive({
      infodata: [],
      infoDetail: {}
    });
    const getData = async () => {
      setTitle(5, 0, 0, () => {
        proxy.$common.fetch({
          'act': 'telfort_teacher',
          msg: ''
        }, res => {
          FFdata.infodata = res.infodata;
        });
      });
    };
    onMounted(() => {
      getData();
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(FFdata).infodata, item => {
        return _openBlock(), _createElementBlock("div", {
          class: "teacherlist",
          key: item.id
        }, [_createElementVNode("div", _hoisted_3, [_createElementVNode("img", {
          src: item.imgcover
        }, null, 8, _hoisted_4)]), _createElementVNode("div", _hoisted_5, [_createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$common.langInfo(item.title, item.title_en)), 1), _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$common.langInfo(item.subtitle, item.subtitleen)), 1), _createElementVNode("div", {
          class: "teachercon editor",
          innerHTML: _ctx.$common.langInfo(item.con, item.conen)
        }, null, 8, _hoisted_8)])]);
      }), 128))])]);
    };
  }
};